<template>
  <Sect class="SectionApva">
    <Wrap>
      <h2>
        APVA parama padengs trečdalį elektrinės įsigijimo kaštų
      </h2>
      <div class="content">
        <p>
          Liepos 1 d. – liepos 31 d. Lietuvos gyventojai gali teikti paraiškas paramai nutolusios saulės elektrinės daliai įsigyti. Šios paramos gavėjams bus išmokama 323 Eur fiksuota suma už kiekvieną kW elektrinės galios. Numatoma išdalinti iki 4,45 mln. Eur paramos. Išmokas gali gauti gyventojojai, nuosavybės teise valdantis gyvenamosios paskirties vieno buto pastatą, gyvenamosios paskirties butą arba sodo namą.
        </p>
        <p>
          Prašymą paramai gauti galite užpildyti
          <a href="https://apvis.apva.lt/paskelbti_kvietimai/aie-namu-ukiams-isigijimas-is-elektriniu-parku" rel="noopener" target="_blank">
            APVA puslapyje.
          </a>
        </p>
      </div>
    </Wrap>
    <!--
    <template v-if="screenSizes.includes('xl')">
      <Blob class="left" name="project-blob-left" />
      <Blob class="right" name="project-blob-right" />
    </template>
    -->
  </Sect>
</template>

<script>
import { getters } from '../../utils'

export default {
  computed: getters('screenSizes'),
}
</script>

<style lang="scss">
section.Sect.SectionApva {
  background: linear-gradient(#5e36d4, #5054da, #3f79e5);
  color: $white;
  margin-bottom: 6rem;
  padding: 8rem 0 6rem;
  position: relative;
  @include md {
    margin-bottom: 8rem;
    padding: 10rem 0;
  }

  &::before {
    background: url('../../../assets/landing/sweep-project.png') no-repeat;
    background-size: cover;
    content: ' ';
    filter: fliph;
    height: 185px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -184px;
    transform: scaleX(-1);
    width: 100%;
    @include xxl {
      background-size: 100% 185px;
    }
  }

  &::after {
    background: url('../../../assets/landing/sweep-why.png') no-repeat;
    background-size: cover;
    bottom: -184px;
    content: ' ';
    height: 185px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    @include xxl {
      background-size: 100% 185px;
    }
  }

  a {
    border-color: $text-color-light;
    color: inherit;

    &:hover {
      border-color: $white;
    }
  }

  h2 {
    color: inherit;
    font-size: $h3;
    margin-bottom: 3rem;
    @include md {
      font-size: $h2;
    }
  }

  .content {
    font-size: $h4;
    line-height: 2rem;

    p {
      margin-bottom: 2rem;
    }
  }

  .Blob {
    z-index: 1;

    &.left {
      left: -3rem;
      top: 36%;
      width: 21vw;
      @include xxl {
        left: 0;
      }
    }

    &.right {
      right: 0;
      top: 2%;
      width: 22vw;
      @include xxl {
        top: 5%;
      }
    }
  }
}
</style>
