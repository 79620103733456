<template>
  <Sect class="SectionWhyWorth">
    <Wrap class="lg">
      <h2>Saulės bendruomenės elektrinės savininku tapti verta</h2>
      <Row>
        <Column v-for="({ icon, title, text }, index) in whyWorth" :key="index" :md="4">
          <IconColumn :icon="icon" :text="text" :title="title" />
        </Column>
      </Row>
    </Wrap>
  </Sect>
</template>

<script>
import IconColumn from './../../components/landing/IconColumn'
import whyWorth from './whyWorth.json'

export default {
  components: { IconColumn },
  data() {
    return { whyWorth }
  },
}
</script>

<style lang="scss">
section.Sect.SectionWhyWorth {
  margin: 0 0 4rem;
  padding-top: 1rem;
  @include lg {
    margin: 4rem 0 8rem;
  }

  h2 {
    font-size: $h4;
    margin-bottom: 1.5rem;
    @include lg {
      font-size: $h2;
      margin-bottom: 4rem;
    }
  }

  .IconColumn {
    h3 {
      font-size: 1.1rem;
      @include lg {
        font-size: 1.3rem;
      }
      @include xl {
        font-size: 1.8rem;
      }
      // font-size: 1.8rem;
    }

    .img {
      border: none;
      color: $black;
      font-weight: $bold;
    }
  }
}
</style>
