<template>
  <Sect class="SectionLoan">
    <Wrap>
      <h2>
        Įsigyti galite ir išsimokėtinai
      </h2>
      <div class="content">
        <p>
          Saulės bendruomenė bendradarbiaudama su finansų institucijomis siūlo išskirtines išsimokėjimo sąlygas nutolusios elektrinės daliai įsigyti:
        </p>
        <ul>
          <li>
            metinės palūkanos tik 3,9%, kai sutarties terminas iki 12 mėn. arba 5,5%, kai sutarties terminas daugiau nei 12 mėn.;
          </li>
          <li>
            išsimokėjimo terminas iki 120 mėn.;
          </li>
          <li>
            nereikalinga pradinė įmoka;
          </li>
          <li>
            jokių baudų už anksčiau laiko grąžintą paskolos dalį.
          </li>
        </ul>
      </div>
    </Wrap>
    <template v-if="screenSizes.includes('xl')">
      <Blob class="left" name="project-blob-left" />
      <Blob class="right" name="project-blob-right" />
    </template>
  </Sect>
</template>

<script>
import { getters } from '../../utils'

export default {
  computed: getters('screenSizes'),
}
</script>

<style lang="scss">
section.Sect.SectionLoan {
  background: linear-gradient(#5e36d4, #5054da, #3f79e5);
  color: $white;
  margin-bottom: 4rem;
  padding: 8rem 0 6rem;
  position: relative;
  @include md {
    margin-bottom: 8rem;
    padding: 10rem 0;
  }

  &::before {
    background: url('../../../assets/landing/sweep-project.png') no-repeat;
    background-size: cover;
    content: ' ';
    height: 185px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: -184px;
    width: 100%;
    @include xxl {
      background-size: 100% 185px;
    }
  }

  &::after {
    background: url('../../../assets/landing/sweep-why.png') no-repeat;
    background-size: cover;
    bottom: -184px;
    content: ' ';
    height: 185px;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    @include xxl {
      background-size: 100% 185px;
    }
  }

  h2 {
    color: inherit;
    font-size: $h3;
    margin-bottom: 3rem;
    @include md {
      font-size: $h2;
    }
  }

  .content {
    font-size: $h4;
    line-height: 2rem;

    p {
      margin-bottom: 2rem;
    }

    ul {
      margin-left: 1.5rem;
    }
  }

  .Blob {
    z-index: 1;

    &.left {
      left: -3rem;
      top: 36%;
      width: 21vw;
      @include xxl {
        left: 0;
      }
    }

    &.right {
      right: 0;
      top: 2%;
      width: 22vw;
      @include xxl {
        top: 5%;
      }
    }
  }
}
</style>
