<template>
  <Row class="IconColumns no-gutters" :class="{ progression: hasProgression }">
    <Column
      v-for="({ icon, title, text }, index) in data"
      :key="index"
      :md="round(12 / data.length)">
      <IconColumn :class="classes" :icon="icon" :text="text" :title="title">
        <div v-if="$slots[`text-${index + 1}`]">
          <slot :name="`text-${index + 1}`" />
        </div>
      </IconColumn>
      <div v-if="hasProgression && index !== data.length - 1" class="IconColumns-arrow">
        <Icon :name="isDesktop ? 'right' : 'down'" />
      </div>
    </Column>
  </Row>
</template>

<script>
import IconColumn from './IconColumn'
import { getters } from '../../utils'

export default {
  components: { IconColumn },
  props: {
    classes: String,
    data: Array,
    hasProgression: Boolean,
  },
  computed: getters('isDesktop'),
  methods: {
    round(amount) {
      return Math.round(amount)
    },
  },
}
</script>

<style lang="scss">
.IconColumns {
  padding: 0 1.5rem;
  @include md {
    padding: 0;
  }

  &-arrow {
    bottom: -0.75rem;
    color: $main;
    font-size: $h1;
    pointer-events: none;
    position: absolute;
    right: 50%;
    text-align: center;
    transform: translateX(2rem);
    @include md {
      bottom: auto;
      font-size: 6rem;
      right: -25%;
      top: 0;
      transform: none;
    }
    @include lg {
      right: -20%;
    }
    @include xl {
      right: -15%;
    }
  }
}

.IconColumns.progression {
  .IconColumn {
    margin-bottom: 3.5rem;
  }
}
</style>
