<template>
  <Sect class="HowItWorks">
    <Wrap class="lg">
      <h2>{{ title }}</h2>
      <IconColumns :data="how" :hasProgression="true" />
      <Wrap class="etc">
        <Row>
          <Column :md="4">
            <p>
              Kad galėtumėte naudotis „Saulės bendruomenės“ privalumais, skirstomųjų tinklų operatorius Jūsų namuose įrengs išmaniuosius elektros energijos skaitiklius ir tapsite gaminančiu vartotoju.
            </p>
          </Column>
          <Column :md="4">
            <p>
              Kiekvieno mėnesio pabaigoje, skirstomųjų tinklų operatorius pateiks sąskaitą už pasinaudojimą elektros tinklais („elektros pasaugojimą“). Jeigu Jūsų saulės elektrinės dalies sugeneruoto energijos kieko nepakaks Jūsų poreikiams padengti, taip pat gausite papildomą sąskaitą už trūkstamo kieko įsigijimą.
            </p>
          </Column>
          <Column :md="4">
            <p>
              Nesunaudotas elektros kiekis atiduodamas tinklams neatlygintinai kiekvienų metų kovo 31 d., todėl labai svarbu parinkti tinkamo dydžio elektrinės dalį.
            </p>
          </Column>
        </Row>
        <slot />
      </Wrap>
    </Wrap>
  </Sect>
</template>

<script>
import IconColumns from './IconColumns'
import how from './how.json'

export default {
  components: { IconColumns },
  props: {
    title: {
      type: String,
      default: 'Kaip tai veikia?',
    },
  },
  data() {
    return { how }
  },
}
</script>

<style lang="scss">
section.Sect.HowItWorks {
  margin: 0 0 4rem;
  padding-top: 1rem;
  @include lg {
    margin: 4rem 0 8rem;
  }

  h2 {
    font-size: $h4;
    margin-bottom: 1.5rem;
    @include lg {
      font-size: $h2;
      margin-bottom: 4rem;
    }
  }

  p {
    margin-bottom: 2rem;
    @include md {
      margin: 0;
    }
  }

  .etc {
    font-size: $h5;
    font-weight: $regular;
    line-height: $h4;
    text-align: center;
    @include lg {
      line-height: $lh;
      text-align: left;
    }
  }
}
</style>
