<template>
  <div id="LandingSubsidy">
    <div class="top">
      <Wrap class="xl">
        <MainLogo />
        <Route class="Btn danger ib reserve" :to="{ hash: '#signup' }">
          Rezervuok
        </Route>
      </Wrap>
    </div>
    <Hero
      :formats="['webp', 'png']"
      text="Taupyti generuodami elektrą iš saulės energijos galite nepriklausomai nuo to gyvenate bute ar nuosavame name. Dabar, pasinaudoję APVA parama, 34 procentais sumažinsite savo išlaidas nutolusios elektrinės daliai įsigyti ir dar greičiau mėgausitės sumažėjusiomis sąskaitomis už elektrą.">
      <div slot="title">
        Pasinaudokite APVA parama ir įsirenkite saulės elektrinę 34% pigiau
      </div>
      <div slot="actions">
        <Route class="Btn ib danger" :to="{ hash: '#signup' }">
          Rezervuok
          <Icon name="right" />
        </Route>
        <Route class="Btn ib" :to="{ hash: '#verta' }">
          Sužinok daugiau
          <Icon name="right" />
        </Route>
      </div>
    </hero>

    <SectionWhyWorth id="verta" />

    <SectionApva />

    <Calculator
      :paragraphs="calculatorParagraphs"
      :project="mainProject"
      title="Sužinokite, per kiek laiko atsipirks jūsų saulės elektrinės dalis"
      :value.sync="amount">
      <Route class="Btn lg danger cta" :to="{ hash: '#signup' }">
        Rezervuok
      </Route>
    </Calculator>

    <HowItWorks
      title="Kaip veikia elektros tiekimas iš nutolusios saulės elektrinės">
      <Route class="Btn danger" :to="{ hash: '#signup' }">
        Tapti Saulės bendruomenės nariu
      </Route>
    </HowItWorks>

    <SectionLoan />

    <Wrap class="md">
      <FormSignup
        id="signup"
        title="Prisijunkite prie Saulės bendruomenės ir rezervuokite savo elektrinės dalį" />
    </Wrap>
    <!-- <Footer /> -->
    <FooterLandingSubsidy />
  </div>
</template>

<script>
import Calculator from './components/Calculator'
// import Feature from './components/landing/Feature'
import FooterLandingSubsidy from './components/landingSubsidy/FooterLandingSubsidy'
// import Footer from './components/Footer'
import FormSignup from './components/forms/FormSignup'
import Hero from './components/landing/Hero'
import HowItWorks from './components/landing/HowItWorks'
import MainLogo from './components/TopBar/MainLogo'
import Meta from './mixins/Meta'
import SectionApva from './components/landingSubsidy/SectionApva'
import SectionLoan from './components/landingSubsidy/SectionLoan'
import SectionWhyWorth from './components/landingSubsidy/SectionWhyWorth'
import { getters } from './utils'
import why from './components/landing/why.json'

export default {
  mixins: [
    Meta(
      {
        inner: 'Saulės elektros energija',
        separator: '|',
        complement: 'Saulės bendruomenė',
      },
      undefined,
      require('@/assets/social/homepage.jpg'),
    ),
  ],
  components: {
    Calculator,
    FormSignup,
    FooterLandingSubsidy,
    // Footer,
    Hero,
    HowItWorks,
    MainLogo,
    SectionApva,
    SectionLoan,
    SectionWhyWorth,
  },
  data() {
    return {
      amount: 200,
      calculatorParagraphs: [
        'Žinant kiek suvartojate elektros per mėnesį, galima įvertinti kokios galios saulės elektrinės dalį verta įsigyti ir kiek ji padės sutaupyti. Kviečiame pasinaudoti žemiau pateikta skaičiuokle.',
      ],
      why,
    }
  },
  computed: getters('isDesktop', 'mainProject'),
}
</script>

<style lang="scss">
#LandingSubsidy {
  .Hero-body {
    @include md {
      max-width: 80rem;
    }

    h2 {
      font-size: 1.6rem;
      @include md {
        font-size: 2rem;
      }
      @include lg {
        font-size: 3rem;
      }
    }

    p {
      font-size: 1rem;
      @include md {
        font-size: 1.3rem;
      }
      @include lg {
        font-size: 1.6rem;
      }
    }
  }

  > .top {
    position: absolute;
    width: 100%;
    z-index: 7;

    > .Wrap {
      // border-bottom: 1px solid $white;
      padding: 1rem 0.5rem 0.125rem;
      @include xs {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
      }
      @include lg {
        border: 0;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      @include xl {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    .reserve {
      float: right;
      @include md {
        margin-top: 2rem;
      }
    }
  }

  .Calculator {
    contain: content;

    .Btn.cta {
      margin: 3rem auto;
      max-width: 24rem;
    }
  }

  .HowItWorks {
    contain: content;

    .Btn {
      margin: 0 auto;
      max-width: 24rem;
      @include md {
        margin-top: 3rem;
      }
    }
  }

  .FormSignup {
    margin-bottom: 5rem;
    @include lg {
      margin-bottom: 3rem;
    }
  }
}
</style>
