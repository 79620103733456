<template>
  <div class="IconColumn" :class="{ number: typeof icon === 'number' }">
    <div class="head">
      <div class="img">
        <Icon v-if="typeof icon === 'string'" :name="icon" />
        <template v-else>
          {{ icon }}
        </template>
      </div>
    </div>
    <div class="body">
      <h3 v-if="title" v-html="titleFormatted" />
      <div v-if="text" class="text" v-html="text" />
      <div v-else-if="$slots.default">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { getters } from '../../utils'

export default {
  props: {
    icon: [String, Number],
    title: String,
    text: String,
  },
  computed: {
    titleFormatted() {
      if (this.isDesktop) return this.title

      return this.title.replace(/<br\/?>/, ' ')
    },
    ...getters('isDesktop'),
  },
}
</script>

<style lang="scss">
$_icon-size: 5rem;
$_icon-size-lg: 8rem;

.IconColumn {
  margin-bottom: 3rem;
  padding: 0 1rem;
  text-align: center;
  @include lg {
    margin-bottom: 4rem;
  }

  .head {
    color: $main;

    .img {
      border: 2px solid;
      border-color: inherit;
      border-radius: $radius-round;
      color: inherit;
      display: inline-block;
      font-size: $_icon-size / 1.55;
      height: $_icon-size;
      line-height: $_icon-size / 1.1;
      margin-bottom: 0.75rem;
      width: $_icon-size;
      @include lg {
        border: 4px solid;
        font-size: $_icon-size-lg / 1.55;
        height: $_icon-size-lg;
        line-height: $_icon-size-lg * 0.9;
        margin-bottom: 1.5rem;
        width: $_icon-size-lg;
      }
    }
  }

  .body {
    > h3 {
      color: $main;
      font-size: $h4;
      line-height: 1.75rem;
      margin-bottom: 0.5rem;
      @include lg {
        font-size: $h2;
        line-height: 2.5rem;
        margin-bottom: 1rem;
      }
    }

    > .text {
      font-weight: $regular;
      line-height: 1.35rem;
      @include lg {
        line-height: 1.5rem;
      }
    }
  }

  &.inv {
    .head {
      border-color: $white;
      color: $white;
    }

    .body {
      color: $white;

      h3 {
        color: inherit;
      }
    }
  }

  &.number {
    .head .img {
      font-size: $_icon-size / 1.75;
      font-weight: $semibold;
      line-height: $_icon-size * 0.95;
      @include lg {
        font-size: $_icon-size-lg / 1.75;
        line-height: $_icon-size-lg * 0.95;
      }
    }
  }

  &.single-line {
    .body h3 {
      height: auto;
    }
  }
}
</style>
